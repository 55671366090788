import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const AboutMePageTemplate = ({ title, pageTitle, content, contentComponent, url }) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="uk-grid">
      <Helmet title={pageTitle}>
        <meta property="og:title" content={pageTitle}/>
        <meta property="og:url" content={url || '/'}/>
        <link rel="canonical" href={url || '/'} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      <div className="uk-width-1-1">
        <div className="main uk-width-auto section" id="main">
          <div className="widget Blog" data-version="2" id="Blog1">
            <div className="single_post hfeed" id="single_post">
              <article className="post hentry">
                <div className="post_content">
                  <h1 className="post_title entry-title uk-article-title">
                    {title}
                  </h1>
                  <div className="post_body entry-content uk-margin-large-bottom">
                    <p>
                      <PageContent className="content" content={content} />
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

AboutMePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutMePage = ({ data, location }) => {
  const {
    markdownRemark: post,
    site: {
      siteMetadata: {
        title: siteTitle,
        host
      },
    }
  } = data;

  return (
    <Layout>
      <AboutMePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        pageTitle={`${post.frontmatter.title} | ${siteTitle}`}
        content={post.html}
        url={`${host}${location.pathname || '/'}`}
      />
    </Layout>
  )
};

AboutMePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutMePage;

export const aboutMePageQuery = graphql`
  query AboutMePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
        host
      }
    }
  }
`;
